<!--
  价格
    + 如果选择的是“面议”，则不显示输入框
    + 如果选择的是“具体值”，则显示输入框
-->
<template>
  <div>
    <template v-if="mode !== 'static'">
      <!-- 选择器 -->
      <el-select
        style="width:100%"
        placeholder="请选择"
        size="small"
        v-model="selectValue"
        filterable
        clearable
        @change="onSelectChange">
        <el-option
          v-for="optionItem in item.options"
          :key="optionItem"
          :label="optionItem"
          :value="optionItem">
        </el-option>
      </el-select>
      <el-input
        v-if="selectValue === '具体值'"
        style="margin-top: 20px;width:100%"
        v-model="inputValue"
        maxlength="50" show-word-limit
        placeholder="请输入具体价格">
      </el-input>
    </template>
    <template v-else>
      {{ $_value }}
    </template>
  </div>
</template>

<script>
import itemMixin from '../utils/form.item.mixin'
export default {
  mixins: [itemMixin],
  data () {
    return {
      selectValue: '',
      inputValue: ''
    }
  },
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  },
  created () {
    if (this.$_value === '面议') {
      this.selectValue = '面议'
    } else {
      this.inputValue = this.$_value
    }
  },
  watch: {
    selectValue (value) {
      if (value === '面议') {
        this.$_value = '面议'
      } else if (value === '具体值') {
        this.$_value = this.inputValue
      } else {
        this.$_value = ''
      }
    },
    inputValue (value) {
      if (this.selectValue === '面议') {
        this.$_value = '面议'
      } else if (this.selectValue === '具体值') {
        this.$_value = value
      }
    }
  },
  methods: {
    onSelectChange () {
      this.inputValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
