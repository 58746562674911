<template>
  <div class="upload_images">
    <!-- 上传 -->
    <el-upload
      v-if="mode !== 'static'"
      ref="uploadFiles"
      :accept="accept"
      :action="action"
      :headers="headers"
      :on-remove="removeFile"
      :on-success="successUpload"
      :on-error="errorUpload"
      :on-exceed="handleExceed"
      :on-preview="handlePreview"
      :before-upload="beforeUpload"
      :file-list="$_value"
      list-type="picture">
      <el-button size="small" :loading="uploadBtnLoading" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">允许上传jpg,jpeg,png格式的图片，单个文件小于5M</div>
    </el-upload>
    <!-- 展示模式 -->
    <div v-else class="img_list">
      <el-image
        title="点击查看大图"
        class="img_item"
        v-for="imgItem in $_value"
        :key="imgItem.id"
        style="width: 120px; height: 120px"
        :src="imgItem.url"
        fit="cover"
        :preview-src-list="urlArray">
      </el-image>
    </div>
  </div>
</template>

<script>
import cookie from '@/configs/cookie'
import * as api from '@/api'
import itemMixin from '../utils/form.item.mixin'
export default {
  mixins: [itemMixin],
  data () {
    return {
      // 图片上传按钮loading
      uploadBtnLoading: false
    }
  },
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    },
    // 大图预览 图片地址数组
    urlArray () {
      return this.$_value.map(item => {
        return item.url
      })
    },
    // 上传地址
    action () {
      return api.uploadURL
    },
    // 上传携带凭证
    headers () {
      const token = cookie.get(this.globalVar.CMS_TOKEN)
      return {
        Authorization: 'Bearer ' + token
      }
    },
    // 允许上传的类型
    accept () {
      return 'image/png, image/jpg, image/jpeg'
    },
    // 允许上传的最大大小
    maxSize () {
      return 5
    }
  },
  methods: {
    // 上传成功
    successUpload (res, file, fileList) {
      this.uploadBtnLoading = false
      /**
       * uid给el做key
       * name: 给el做名字
       */
      this.$_value.push({
        uid: file.uid,
        name: file.name,
        ...res.data
      })
    },
    // 文件上传之前
    beforeUpload (file) {
      this.uploadBtnLoading = true
      if (this.accept && this.$refs.uploadFiles.accept.search(file.type) === -1) {
        this.uploadBtnLoading = false
        this.$message.warning('上传失败，文件格式不正确！')
        return false
      }
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.uploadBtnLoading = false
        this.$message.warning(`上传失败，文件大于${this.maxSize}M`)
        return false
      }
    },
    // 上传文件出错
    errorUpload (error, file, fileList) {
      this.uploadBtnLoading = false
      console.log(error)
      this.$message.error('文件上传出错!')
    },
    // 删除文件
    removeFile (file, fileList) {
      const index = this.$_value.findIndex(item => {
        return item.uid === file.uid
      })
      this.$_value.splice(index, 1)
    },
    // 文件超出个数限制
    handleExceed (files, fileList) {
      this.uploadBtnLoading = false
      this.$message.warning('上传失败，文件数量过多！')
    },
    // 点击文件列表中的文件
    handlePreview (file) {
      const a = document.createElement('a')
      a.download = file.origin_name
      a.href = file.url
      a.click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload_images {
    width: 100%;
    .img_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .img_item {
        margin-right: 10px;
      }
    }
  }
</style>
